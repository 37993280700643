// maps the content type id to an enum value
export const LayoutTypes = {
  Educational: 'pageEducationalLayout',
  Navigational: 'navigationalLayout',
  Inspirational: 'inspirationalLayout',
  Flexible: 'flexibleLayout',
  GiftCard: 'giftCardLayout',
  Hybrid: 'hybridPlpLayout',
};

export const getLayoutClass = (layoutType) => new Map([
  [LayoutTypes.Educational, 'EducationalLayout'],
  [LayoutTypes.Navigational, 'NavigationalLayout'],
  [LayoutTypes.Inspirational, 'InspirationalLayout'],
  [LayoutTypes.Flexible, 'UniversalLayout'],
  [LayoutTypes.GiftCard, 'GiftCardLayout'],
  [LayoutTypes.Hybrid, 'HybridLayout'],
]).get(layoutType) || 'EducationalLayout';
